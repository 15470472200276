import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CarouselModule } from './carousel/carousel.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AboutMeComponent } from './about-me/about-me.component';
import { ParallaxComponent } from './parallax/parallax.component';
import { SponsoringComponent } from './sponsoring/sponsoring.component';
import { BecomeSponsorComponent } from './become-sponsor/become-sponsor.component';
import { MainPageComponent } from './main-page/main-page.component';
import { RouterModule, Routes } from '@angular/router';
import { InstagramComponent } from './instagram/instagram.component';
import { HttpClientModule } from '@angular/common/http';
import { ContactComponent } from './contact/contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PartnerComponent } from './partner/partner.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CompetitionsComponent } from './competitions/competitions.component';
import { ImpressumComponent } from './impressum/impressum.component';

const appRoutes: Routes = [
  { path: '', component: MainPageComponent },
  { path: 'goenner-werden/:value', component: BecomeSponsorComponent },
  { path: 'goenner-werden', component: BecomeSponsorComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'thankyou', component: ThankyouComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ParallaxComponent,
    SponsoringComponent,
    BecomeSponsorComponent,
    MainPageComponent,
    InstagramComponent,
    ContactComponent,
    PartnerComponent,
    NavBarComponent,
    ThankyouComponent,
    CompetitionsComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      appRoutes
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
