<mat-toolbar>
    <a class="logo" routerLink="/" (click)="scrollTo('home')">
        <img src="../../assets/logos/MH_logo_2020_03_17_s.png"/>
    </a>
    <button class="hamburger-menu" (click)="toggleMenu()">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
    <div *ngIf="sections" class="headers">
        <a *ngFor="let section of sections" routerLink="/" (click)="scrollTo(section.elementID)" [ngClass]="{'active' : currentSections.indexOf(section.elementID) !== -1}"><strong>{{section.label}}</strong></a>
    </div>
    <div class="icons-container">
        <div class="icons">
            <a href="https://www.facebook.com/michelle.heimberg.fanpage/" target ="_blank"><img src="../../assets/icons/facebook.png"></a>
            <a href="https://www.instagram.com/michelle_heimberg" target ="_blank"><img src="../../assets/icons/instagram.png"></a>
            <a href="https://www.linkedin.com/in/michelle-heimberg-oly-058b79255" target ="_blank"><img src="../../assets/icons/linkedin.png"></a>
        </div>
    </div>
    <!--Language functionality is not yet implemented-->
    <div class="lang">
        <button>DE</button>
        <button #frenchTooltip="matTooltip" matTooltip="à venir" (click)="showToolTip('french')">FR</button>
        <button #englishTooltip="matTooltip" matTooltip="Coming soon" (click)="showToolTip('english')">EN</button>
    </div>
</mat-toolbar>
<!--mobile menu-->
<div *ngIf="displayMenu && sections" class="dropdown-menu">
    <span class="close" (click)="toggleMenu()">&times;</span>
    <a *ngFor="let section of sections" routerLink="/" (click)="scrollTo(section.elementID)" [ngClass]="{'active' : currentSections.indexOf(section.elementID) !== -1}"><strong>{{section.label}}</strong></a>
</div>