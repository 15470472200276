import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ScrollService } from '../scroll.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { StateService } from '../state.service';

@Component({
  selector: 'app-become-sponsor',
  templateUrl: './become-sponsor.component.html',
  styleUrls: ['./become-sponsor.component.css']
})

export class BecomeSponsorComponent implements OnInit, OnDestroy {
  /**
   * This Listener ensures that when the become sponsor page is left by using the backbutton of the browser, the user gets returned to the sponsoring section of the main-page.
   */
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.scroll.setElementId('sponsoring');
    this.state.openSponsoringExtended = true;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private scroll:ScrollService,
    private viewPortScroller: ViewportScroller,
    private snackBar: MatSnackBar,
    private state: StateService
    ) {}
    
  value: string = "frei"; //initializes value to default value "frei". This gets overwritten in the ngOnInit call if there is a sensible value in the URL.
  private sub: Subscription;
  form = this.formBuilder.group({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl(''),
    street: new FormControl('', Validators.required),
    plz: new FormControl('', Validators.required),
    country: new FormControl(''),
    amount: new FormControl('', Validators.required),
    text: new FormControl(''),
    listed: new FormControl(false),
  });

  ngOnInit(): void {
    this.viewPortScroller.scrollToPosition([0, 0]); //Ensure that the page gets displayed from the topmost position on load.
    this.sub = this.route.params.subscribe(params => { //Load value from URL and set it in the form if available.
      if (params['value']){
        this.value = params['value'];
        if (this.value !== "frei"){
          this.form.patchValue({amount: this.value});
        }
        
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * This method is called when the form is submitted. It calls the necessary function to propagate the message.
   */
  onSubmit(): void {
    let message = "Sali Michelle, Es het grad öpper s Formular uf de Websitte usgfüllt. Do sind d agaabe:";
    for (const key in this.form.value){
      const value = typeof(this.form.value[key]) === 'string' ? this.form.value[key].trim() : this.form.value[key];
      message += "\n" + key + ": " + value;
    }
    //this.testEmail("Michelle", "wichtelfuerwalthers@gmail.com", message); //This line is for testing purposes only. For prod use this.sendEmail with the same arguments.
    this.sendEmail("Michelle", "info@michelle-heimberg.ch", message);
  }

  /**
   * Test function that does not send an email but logs it to the console. Never use for production
   * @param name: Name the email is adressed to
   * @param email: Email adress that gets listed as sender
   * @param message: Message the email entails
   */
  testEmail(name: String, email: String, message: String){
    console.log("Name", name);
    console.log("Email", email);
    console.log("Message", message);
    this.router.navigateByUrl('/thankyou');
  }

  /**
   * Sends an email via formspree to the client. Note that the email is NOT sent to the param email. You need to set up the receiver on formspree.
   * @param name: Name the email is adressed to
   * @param email: Email adress that gets listed as sender
   * @param message: Message the email entails
   */
  sendEmail(name: String, email: String, message: String) {
    const url = "https://formspree.io/f/xeqwvdep";

    //Set Headers
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    let data = `name=${name}&email=${email}&message=${message}`;

    this.http.post<any>(url, data, httpOptions).subscribe({
        next: () => {
            this.router.navigateByUrl('/thankyou');
        },
        error: () => {
            this.handleError();
        }
    })
  }

  /**
   * Opens the snackbar to display generic error message
   */
  handleError(): void {
    const config : MatSnackBarConfig = {
      duration: 50000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'error',
    };
    const message = "Es ist leider ein Fehler beim Absenden des Formulars vorgefallen. Bitte versuche es erneut oder schreibe mir direkt eine E-Mail. Danke!"
    this.snackBar.open(message, 'x', config);
  }
}