import { Component, Input, OnInit, HostListener } from '@angular/core';
/*
  Displays an image carousel
*/
export interface carouselImages {
  src: string,
  alt: string,
  imageText: string,
  mobileSrc: string
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  @Input() images: carouselImages[] = [];
  @Input() indicators = true;
  @Input() controls = true;
  @Input() autoSlide = false; //set to true if you want the carousel to automatically slide in a certain time interval
  @Input() slideInterval = 5000;
  @Input() mobileWidth = 845; //from this cutoff value of screen width the images display their mobileSrc rather than their src.

  isMobile = false;
  selectedIndex = 0;

  ngOnInit(): void {
    if(this.autoSlide){
      this.autoSlideImages();
    }
    this.checkScreenSize();
  }

  /**
   * Sets an interval to automatically slide the images
   */
  autoSlideImages(): void {
    setInterval(() => {
      this.nextImage();
    }, this.slideInterval);
  }

  /**
   * Selects a particular image as the currently diplayed image
   * @param index the index of the image in the images array
   */
  selectImage(index: number): void {
    this.selectedIndex = index;
  }

  /**
   * Select the image one before the image currently selected
   */
  previousImage(): void {
    this.selectedIndex = this.selectedIndex === 0 ? this.images.length - 1 : this.selectedIndex - 1;
  }
  /**
   * Select the next image after the currently selected one
   */
  nextImage(): void {
    this.selectedIndex = this.selectedIndex === this.images.length -1 ? 0 : this.selectedIndex + 1;
  }

  /**
   * Sets up a listener to check if the mobile friendly versions of the images should be displayed.
   */
  @HostListener('window:resize')
  onWindowResize() {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth < this.mobileWidth;
  }

}
