    <div class="container">
        <app-carousel [images]="images" [autoSlide]="true" [controls]="false" id="home"></app-carousel>
        <app-about-me id="about-me"></app-about-me>
        <app-parallax src="assets/img/parallax1_test.png"></app-parallax>
        <app-instagram id="news"></app-instagram>
        <app-parallax id="parallax2" src="assets/img/parallax1-5-new-new.jpeg"></app-parallax>
        <app-competitions id="competitions"></app-competitions>  
        <app-parallax src="assets/img/parallax2-test.jpg"></app-parallax>
        <app-sponsoring id="sponsoring"></app-sponsoring>
        <app-parallax src="assets/img/parallax3.jpg"></app-parallax>
        <app-partner id="partner"></app-partner>
        <app-contact id="contact"></app-contact>
        <div class="footer">
            <p>&copy; Michelle Luisa Heimberg 2024 | Website kreiert von <a href="https://sommerhalder-websolutions.ch" target="_blank">Sommerhalder Websolutions</a> | <a (click)="navigateToImpressum()">Impressum</a></p>
        </div>
    </div>

