<div class="container">
    <h2>Kontaktadresse</h2>
    <div>
        <p>Michelle Heimberg</p>
        <a href="mailto:info@michelle-heimberg.ch">info@michelle-heimberg.ch</a>
        <p>Badenerstrasse 26</p>
        <p>CH-5442 Fislisbach</p>
    </div>  
    <h2>Urheberrechte</h2>
    <p class="text">Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website gehören ausschliesslich Michelle Heimberg oder den speziell genannten Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus einzuholen.</p>
    <h2>Realisation</h2>
    <div>
        <p>Diese Website wurde realisiert von:</p>
        <p>Eric Sommerhalder</p>
        <a href="mailto:eric@sommerhalder-websolutions.ch">eric@sommerhalder-websolutions.ch</a>
    </div>
</div>
