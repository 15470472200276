<div class="container">
    <h1>Gönner werden</h1>
    <article class="intro">Auf dem Weg zu den Olympischen Spielen 2028 in Los Angeles bin ich auf die Unterstützung von sportbegeisterten Menschen und Unternehmen angewiesen.<br>
      Möchtest auch du zu meinem Erfolg beitragen? Dann werde jetzt Mitglied in meinem Gönnerclub!<br>
      Ich würde mich freuen, dich willkommen zu heissen, gemeinsam Erfolge zu feiern und mit deiner Unterstützung den Weg zu den Olympischen Spielen weiter zu gehen.<br>
      Herzlichen Dank!</article>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="name-container">
        <div class="input-container">
            <label for="firstname">
              Vorname *
            </label>
            <input id="firstname" type="text" formControlName="firstname">
            <div *ngIf="form.controls.firstname.invalid && (form.controls.firstname.dirty || form.controls.firstname.touched)">
              <small class="error-message" *ngIf="form.controls.firstname.errors.required">Vorname ist erforderlich.</small>
            </div>
        </div>
        <div class="input-container">
          <label for="lastname">
            Nachname *
          </label>
          <input id="lastname" type="text" formControlName="lastname">
          <div *ngIf="form.controls.lastname.invalid && (form.controls.lastname.dirty || form.controls.lastname.touched)">
            <small class="error-message" *ngIf="form.controls.lastname.errors.required">Nachname ist erforderlich.</small>
          </div>
        </div>
      </div>
        <div class="input-container">
            <label for="email">
              E-Mail Adresse *
            </label>
            <input id="email" type="email" formControlName="email">
            <div *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)">
              <small class="error-message" *ngIf="form.controls.email.errors.required">E-Mail ist erforderlich.</small>
            </div>
        </div>
        <div class="input-container">
          <label for="phone">
            Telefon
          </label>
          <input id="phone" type="text" formControlName="phone">
        </div>
        <div class="input-container">
          <label for="street">
            Strasse und Hausnummer *
          </label>
          <input id="street" type="text" formControlName="street">
          <div *ngIf="form.controls.street.invalid && (form.controls.street.dirty || form.controls.street.touched)">
            <small class="error-message" *ngIf="form.controls.street.errors.required">Strasse und Hausnummer sind erforderlich.</small>
          </div> 
        </div>
        <div class="input-container">
          <label for="plz">
            Postleitzahl und Ort *
          </label>
          <input id="plz" type="text" formControlName="plz">
          <div *ngIf="form.controls.plz.invalid && (form.controls.plz.dirty || form.controls.plz.touched)">
            <small class="error-message" *ngIf="form.controls.plz.errors.required">Postleitzahl und Ort sind erforderlich.</small>
          </div> 
        </div>
        <div class="input-container">
          <label for="country">
            Land
          </label>
          <input id="country" type="text" formControlName="country">
        </div>
        <div class="input-container">
            <label for="amount">
              Mitgliederbeitrag in CHF *
            </label>
            <input id="amount" type="text" formControlName="amount">
            <div *ngIf="form.controls.amount.invalid && (form.controls.amount.dirty || form.controls.amount.touched)">
              <small class="error-message" *ngIf="form.controls.amount.errors.required">Mitgliederbeitrag ist erforderlich.</small>
            </div>     
        </div>
        <div class="input-container">
          <label for="text">
            Nachricht schreiben
          </label>
          <textarea id="text" type="text" formControlName="text"></textarea>
      </div>
        <div class="input-container row">
            <input type="checkbox" formControlName="listed" id="listed"/>
            <label for="listed">Mein Name darf auf der Webseite unter "Mitglieder Gönnerclub" gelistet werden.</label>
        </div>
        <button class="button" type="submit" [ngClass]="{'invalid': form.invalid}" [disabled]="form.invalid">Abschicken</button>
    </form>
    <h2>Für anonyme Spenden</h2>
    <p>Credit Suisse, CH-8001 Zürich<br>CH32 0483 5163 3359 8000 0<br>Michelle Luisa Heimberg<br>CH-5000 Aarau</p>
</div>