import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../state.service';

@Component({
  selector: 'app-sponsoring',
  templateUrl: './sponsoring.component.html',
  styleUrls: ['./sponsoring.component.css']
})
export class SponsoringComponent implements OnInit{
  extended = false; // stores whether the tiles are displayed

  constructor(private router: Router, private state: StateService) {}

  ngOnInit(){
    if (this.state.openSponsoringExtended){ //initialize with tiles displayed
      this.extended = true;
      this.state.openSponsoringExtended = false;
    }
  }

  /**
   * Redirects to the subpage with the url parameter filled in
   * @param value the url parameter
   */
  goToSubPage(value: string): void {
    this.router.navigate(['/goenner-werden', value])
  }

  /**
   * toggles whether the tiles are displayed
   */
  toggleExtended(): void {
    this.extended = !this.extended;
  }
}
