import { Component, OnInit } from '@angular/core';
import { ScrollService } from '../scroll.service';
import { carouselImages } from '../carousel/carousel.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  constructor(private scroll: ScrollService, private router: Router) { }

  ngOnInit(): void {
    /**
     * Checks whether the ScrollService was handed an element to scroll to on page redirect.
     */
    if (this.scroll.elementId){
      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById(this.scroll.elementId).offsetTop -88 ,
          behavior: 'smooth'
        });
      }, 800);
    } 
    
  }

  /*
  Hardcode images to display in the carousel with their alt texts. Follows format of interface carouselImages.
  */
  images: carouselImages[] = [
    {
      src: 'assets/img/carousel2.jpg',
      alt: 'Michelle mit Medallie',
      imageText: "",
      mobileSrc: "assets/img/carousel2.jpg"
    },
    {
      src: 'assets/img/carousel3.jpg',
      alt: 'Michelle im Sprung',
      imageText: "Bild von Valentin Flauraud",
      mobileSrc: 'assets/img/carousel3_mobile.jpg'
    },
    {
      src: 'assets/img/carousel1.jpg',
      alt: "Michelle im Sprung",
      imageText: "Bild von Patrick B. Kreamer",
      mobileSrc: "assets/img/carousel1_mobile.jpg"
    }
  ]

  /**
   * Navigate to impressum
  */
  navigateToImpressum(){
    this.router.navigateByUrl('/impressum');
  }
}
