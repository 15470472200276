import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ScrollService } from '../scroll.service';
/**
 * Data structure for the headers of the navbar
 */
export interface Section {
  elementID: string;
  label: string;
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  @ViewChild("englishTooltip") englishTooltip: MatTooltip; //references to the two tooltips. Remove after language implementation.
  @ViewChild("frenchTooltip") frenchTooltip: MatTooltip
  @Input() sections: Section[]; //sections the navbar lists as titles and can scroll to
  currentSections: string[] = []; //sections that are currently in the viewport
  displayMenu = false; //toggle for mobile menu
  percentVisible = 40; //cut off value how much percent of a section needs to be in the viewport to count
  constructor(public router: Router, private scroll: ScrollService) { }
  
  /**
   * Sets a scroll listener that checks which sections are currently in the viewport
   */
  @HostListener('window:scroll', ['$event']) getScrollHeight() {
    this.currentSections = [];
    for (let i = 0; i < this.sections.length; i++){
      const element = document.getElementById(this.sections[i].elementID) as HTMLElement;
      if(element){
        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        if (!(Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < this.percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < this.percentVisible
          )){
            this.currentSections.push(this.sections[i].elementID);
          } 
      }
      
    }
 }
  /**
   * Scrolls the viewport to the selected section. Callable on the main route or from any subpage.
   * @param id the elementID to scroll to
   */
  scrollTo(id: string) {
    this.displayMenu = false; //close mobile menu
    if (this.router.url === '/') {
      setTimeout(() => {
        window.scrollTo({
          top: document.getElementById(id).offsetTop - 88, //88 is a magic number somehow dependent on the height of the navbar
          behavior: 'smooth'
        });
      });
    } else { //if we're not on the mainpage, we hand the id to the scroll service to scroll after the mainpage has completely loaded
      this.scroll.setElementId(id);
    }
  }

  /**
   * Toggles the mobile menu
   */
  toggleMenu(){
    this.displayMenu = !this.displayMenu;
  }
  /**
   * Shows the tooltip onClick for mobile and tablet devices
   * @param lang parameter to distinguish which tooltip to show, supported currently: "english" and "french"
   */
  showToolTip(lang: string){
    if (lang === "english"){
      this.englishTooltip.show();
    }

    if (lang === "french"){
      this.frenchTooltip.show();
    }
  }

}
