import { Component } from '@angular/core';
import { Section } from './nav-bar/nav-bar.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  sections: Section[] = [
    {elementID: 'about-me', label: "Über mich"},
     {elementID: 'news', label: "News"},
     {elementID: 'competitions', label: 'Wettkämpfe'},
     {elementID: 'sponsoring', label: 'Gönnerclub'},
     {elementID: 'partner', label: 'Partner'},
     {elementID: 'contact', label: 'Kontakt'}
    ];
}
