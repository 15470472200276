import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InstagramService } from '../instagram.service';

/**
 * Interface that represents a Instagram Post returned from the API.
 */
export interface Post {
  url: string; //link to the source of the image or video
  caption: string; //instagram caption
  mediaType: string; //Media types are: IMAGE, VIDEO or CAROUSEL_ALBUM https://developers.facebook.com/docs/instagram-basic-display-api/reference/media/
  timeStamp: string; //timeStamps to sort be recency
  children?: Child[]; //children array for CAROUSEL_ALBUMs
  thumbnail?: string; //video thumbnails
}

/**
 * Children interface for the carousel albums
 */
export interface Child {
  url: string;
  thumbnail: string;
  mediaType: string; //IMAGE or VIDEO
}

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.css']
})

export class InstagramComponent implements OnInit {
  @ViewChild("videoPlayer", {static: false}) videoPlayer: ElementRef; //reference to the videoPlayer to toggle play from ts
  clickedPost: Post; //reference to the selected post for modal view
  selectedIndex = 0; //index of the activeChild
  activeChild: Child; //currently selected child if the clickedPost is a Carousel Album
  error = false; // gets set to true on api issues
  constructor(private instaService: InstagramService) {}
    posts: Post[] = [];
    ngOnInit(): void {
      this.instaService.getImages(9).subscribe(res => {
      //console.log(JSON.stringify(res));
      // Load images from api to this.posts
      //this.instaService.getImagesDummy().subscribe(res => { //only use this method for development. For production the getImages function has to be used.
        this.error = false;  
        if (res.hasOwnProperty('data') && Array.isArray(res['data'])){
          for (const post of res['data']) {
            if (post.hasOwnProperty('id')){
                if (post["media_type"] == 'IMAGE'){
                  this.posts.push({url: post["media_url"], caption: post["caption"], mediaType: post["media_type"], timeStamp: post["timestamp"]});
                }
                if (post["media_type"] == 'VIDEO'){
                  this.posts.push({url: post["media_url"], caption: post["caption"], mediaType: post["media_type"], timeStamp: post["timestamp"], thumbnail: post["thumbnail_url"]});
                }
                if (post["media_type"] == 'CAROUSEL_ALBUM'){
                  const children: Child[] = [];
                  for (const child of post["children"]["data"]){
                    const thumbnail = child.hasOwnProperty("thumbnail_url") ? child["thumbnail_url"] : "";
                    children.push({url: child["media_url"], thumbnail: thumbnail, mediaType: child["media_type"]});
                  }
                  const leadThumbnail = post.hasOwnProperty("thumbnail_url") ? post["thumbnail_url"] : "";
                  this.posts.push({url: post["media_url"], caption: post["caption"], mediaType: post["media_type"], timeStamp: post["timestamp"], children: children, thumbnail: leadThumbnail});
                } 
            }
          }
          //Sort posts by recency
          this.posts = this.posts.sort((n1, n2) => {
            if (n1.timeStamp > n2.timeStamp){
              return -1;
            }
            if (n2.timeStamp > n1.timeStamp){
              return 1;
            }
            return 0;
          })
        }
        
      },
      () => {
        this.error = true;
      }
      );
  }

  /**
   * Handles the click on a tile in the grid. Displays the modal view and sets up carousel logic
   * @param post The clicked Post
   */
  postClicked(post: Post): void {
    var modal = document.getElementById("modal") as HTMLElement;
    modal.style.display = "flex";
    this.clickedPost = post;

    if (post.mediaType == "CAROUSEL_ALBUM"){
      this.selectedIndex = 0;
      this.activeChild = post.children[this.selectedIndex];
    }

    if (post.mediaType == "VIDEO"){
      if (this.videoPlayer){
        this.videoPlayer.nativeElement.src = post.url;
      }
    }
  }
  
  /**
   * Handles closing the modal view
   */
  close(): void {
    var modal = document.getElementById("modal") as HTMLElement;
    modal.style.display = "none";
    this.selectedIndex = 0;
    this.videoPlayer?.nativeElement.pause();
  }

  /**
   * Handles a click on the post in the modal view. Redirects to the general instagram page.
   * @param isVideo if this is true, also pause the video.
   */
  modalPostClicked(isVideo: boolean): void {
    if (isVideo) {
      this.videoPlayer.nativeElement.pause();
    }
    this.close();
    window.open("https://www.instagram.com/michelle_heimberg", "_blank");
  }

  /**
   * Navigate through the children of a carousel
   */
  previousChild(): void {
    this.selectedIndex = this.selectedIndex === 0 ? this.clickedPost.children.length - 1 : this.selectedIndex - 1;
    this.activeChild = this.clickedPost['children'][this.selectedIndex];
    if (this.videoPlayer && this.activeChild.mediaType === 'VIDEO'){
      this.videoPlayer.nativeElement.src = this.activeChild.url;
    }
  }

  /**
   * Navigate through the children of a carousel
   */
  nextChild(): void {
    this.selectedIndex = this.selectedIndex === this.clickedPost.children.length -1 ? 0 : this.selectedIndex + 1;
    this.activeChild = this.clickedPost['children'][this.selectedIndex];
    if (this.videoPlayer && this.activeChild.mediaType === 'VIDEO'){
      this.videoPlayer.nativeElement.src = this.activeChild.url;
    }
  }
}
