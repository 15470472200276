<div class="container">
    <h1>Kontakt</h1>
    <h2 class="pc">Allgemeine Anfragen (Presse, Sponsoring etc.)</h2>
    <h2 class="mobile">Allgemeine Anfragen</h2>
    <div class="email">
        <a href="mailto:info@michelle-heimberg.ch"><i class="fa fa-envelope"></i></a>
        <a class="email-text" href="mailto:info@michelle-heimberg.ch">info@michelle-heimberg.ch</a>
    </div>
    <h2>Fanpost</h2>
    <div>
        <p class="address-text">Michelle Heimberg</p>
        <p class="address-text">Badenerstrasse 26</p>
        <p class="address-text">CH-5442 Fislisbach</p>
    </div>
    <p>Bitte frankiertes Antwortcouvert beilegen</p>
</div>
