<h1>News</h1>
<div class="container" *ngIf="!error">
    <div class="post-grid">
        <div class="grid-container" *ngFor="let post of posts; let i = index" [ngSwitch]="post.mediaType" [ngClass]="{'last-post' : i === 8}">

            <div class="post-container" *ngSwitchCase="'IMAGE'">
                <div class="icon-post" (click)="postClicked(post)">
                    <img [src]="post.url">
                    <div class="caption-container">
                        <div class="caption">
                            {{post.caption}}
                        </div>
                    </div>
                </div>   
            </div>

            <div class="post-container" *ngSwitchCase="'VIDEO'">
                <div class="icon-post" (click)="postClicked(post)">
                    <i class="fas fa-film icon"></i>
                    <img [src]="post.thumbnail" >
                    <div class="caption-container">
                        <div class="caption">
                            {{post.caption}}
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="post-container" *ngSwitchCase="'CAROUSEL_ALBUM'">
                <div class="icon-post" (click)="postClicked(post)">
                    <i class="fas fa-solid fa-images icon"></i>
                    <img [src]="post.url">
                    <div class="caption-container">
                        <div class="caption">
                            {{post.caption}}
                        </div>
                    </div>
                </div>    
            </div>
            <div class="mobile-caption">
                <p>{{post.caption}}</p>
            </div>
        </div>
    </div>
</div>

<!-- Modal content -->
<div id="modal" class="modal" (click)="close()">    
    <div class="modal-content" *ngIf="clickedPost">   
        <img  class="modal-media" *ngIf="clickedPost.mediaType === 'IMAGE'" [src]="clickedPost.url" (click)="modalPostClicked(false); $event.stopPropagation();">
        <video #videoPlayer class="modal-media" *ngIf="clickedPost.mediaType === 'VIDEO'" [poster]="clickedPost.thumbnail" controls><!--(click)="modalPostClicked(true); $event.stopPropagation(); $event.preventDefault();">-->
            <source [src]="clickedPost.url">
        </video>
        <div class="carousel-viewer" *ngIf="clickedPost.mediaType === 'CAROUSEL_ALBUM'">
            <img *ngIf="activeChild.mediaType === 'IMAGE'" [src]="activeChild.url" (click)="modalPostClicked(false); $event.stopPropagation();">
            <video #videoPlayer *ngIf="activeChild.mediaType === 'VIDEO'" [poster]="activeChild.thumbnail" controls><!--(click)="modalPostClicked(true); $event.stopPropagation(); $event.preventDefault();">-->
                <source [src]="activeChild.url">
            </video>
            <button class="btn-carousel btn-prev" (click)="previousChild(); $event.stopPropagation();">
                <i class="fas fa-solid fa-arrow-circle-left icon-carousel icon-prev"></i>
            </button>
            <button class="btn-carousel btn-next" (click)="nextChild(); $event.stopPropagation();">
                <i class="fas fa-arrow-circle-right icon-carousel icon-next"></i>
            </button> 
        </div>
        <div class="modal-sidebar">
        <article class="modal-sidebar-container">
            <a class="modal-header" href="https://www.instagram.com/michelle_heimberg" target="_blank">&#64;michelle_heimberg</a>
            <p>{{clickedPost.caption}}</p>
        </article>
      </div>
      <span class="close" (click)="close()">&times;</span>
    </div>
  </div>

<!-- Error Content-->
<div *ngIf="error" class="error-container">
    <p>Leider ist ein Fehler beim Laden der letzten News vorgefallen. Besuche doch mein Instagram!</p>
    <a href="https://www.instagram.com/michelle_heimberg" target="_blank">
        <img src="assets/icons/instagram.png">
    </a>
</div>