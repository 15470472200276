
<h1>Gönnerclub</h1>
<div class="article-container">
	<article class="intro">
		Auf dem Weg zu den Olympischen Spielen 2028 in Los Angeles bin ich auf die Unterstützung von sportbegeisterten Menschen und Unternehmen angewiesen.<br>
		Möchtest auch du zu meinem Erfolg beitragen? Dann werde jetzt Mitglied in meinem Gönnerclub!<br>
		Ich würde mich freuen, dich willkommen zu heissen, gemeinsam Erfolge zu feiern und mit deiner Unterstützung den Weg zu den Olympischen Spielen weiter zu gehen. Welche Gegenleistungen dich erwarten, erfährst du untenstehend.<br>
		Herzlichen Dank!
	</article>
</div>
<div class="sponsor-button-container">
	<button class="sponsor-button wide" (click)="toggleExtended()">{{extended ? "Optionen verstecken" : "Optionen wählen und Gönner werden"}}</button>
</div>

<div *ngIf="extended" class="extended-container">
	<ul class="tilesWrap">
		<li class="tile" (click)="goToSubPage('100')">
			<h2>100</h2>
			<h3>100 CHF</h3>
			<ul class="sublist">
				<li>Dankeskarte & Gönnergeschenk</li>
			</ul>
		</li>
		<li class="tile" (click)="goToSubPage('250')">
			<h2>250</h2>
			<h3>250 CHF</h3>
			<ul class="sublist">
				<li>
					Dankeskarte & Gönnergeschenk
				</li>
				<li>
					Einladung zum jährlichen Gönnerevent
				</li>
			</ul>
		</li>
		<li class="tile" (click)="goToSubPage('500')">
			<h2>500</h2>
			<h3>500 CHF</h3>
			<ul class="sublist">
				<li>
					Dankeskarte & Gönnergeschenk
				</li>
				<li>
					Einladung zum jährlichen Gönnerevent
				</li>
				<li>
					Einladung zu einem gemeinsamen Training mit Michelle
				</li>
			</ul> 
		</li>
		<li class="tile" (click)="goToSubPage('frei')">
			<h2>Frei</h2>
			<h3>Freier Betrag</h3>
			<p>
				Die Gegenleistungen richten sich nach den drei bestehenden Kategorien.
			</p>
		</li>
	</ul>

	<div class="sponsor-button-container">
		<button class="sponsor-button" (click)="goToSubPage('frei')">Gönner werden</button>
	</div>
</div>

<div class="members">
	<div class="members-text">
		<h2>Mitglieder</h2>
			<ul>
				<li>
					Reto Baliarda
				</li>
				<li>
					Johann Colombo
				</li>
				<li>
					Franz Fischer
				</li>
				<li>
					Christian Greuter
				</li>
				<li>
					Kurt Henauer
				</li>
				<li>
					Nicole Hitz
				</li>
				<li>
					Didier Kreienbühl
				</li>
				<li>
					Thomas Müller
				</li>
			</ul>
	</div>
</div>

