    <h1>Partner</h1>
    <div class="sponsors">
        <a href="https://www.sporthilfe.ch/de.html" target ="_blank"><img src="assets/logos/schweizer sporthile.png"></a>
        <a href="https://www.vtg.admin.ch/de/organisation/kdo-ausb/lvb-g-rttg-abc/komp-zen-sport/spitzensport.html" target ="_blank"><img src="assets/logos/spitzensport-rs.png"></a>
        <a href="https://www.swiss-aquatics.ch/" target ="_blank"><img src="assets/logos/swiss_aquatics_diving.png"></a>
        <a href="https://www.fritz-gerber-stiftung.ch/" target ="_blank"><img src="assets/logos/fritz_gerber_stiftung.png"></a>
        <a href="https://voegele-optik.ch/" target ="_blank"><img src="assets/logos/voegele_optik.png"></a>
        <a href="https://www.heimberg-immo.ch/" target ="_blank"><img src="assets/logos/heimberg_imobilien.png"></a>
    </div>
    <div class="sponsors center">
        <a href="https://skilllab.ch" target ="_blank"><img src="assets/logos/skilllab_logo.png"></a>
        <a href="https://www.mauronmusy.com/fr" target ="_blank"><img src="assets/logos/mauron_musy.png"></a>
        <a class="extra-padding" href="https://www.taegi.ch" target="_blank"><img src="assets/logos/taegi.png"/></a>
    </div>
    <!--mobile version-->
    <div class="mobile">
        <a href="https://www.sporthilfe.ch/de.html" target ="_blank"><img src="assets/logos/schweizer sporthile.png"></a>
        <a href="https://www.vtg.admin.ch/de/organisation/kdo-ausb/lvb-g-rttg-abc/komp-zen-sport/spitzensport.html" target ="_blank"><img src="assets/logos/spitzensport-rs.png"></a>
        <a href="https://www.swiss-aquatics.ch/" target ="_blank"><img src="assets/logos/swiss_aquatics_diving.png"></a>
    </div>
    <div class="mobile">
        <a href="https://www.fritz-gerber-stiftung.ch/" target ="_blank"><img src="assets/logos/fritz_gerber_stiftung.png"></a>
        <a href="https://voegele-optik.ch/" target ="_blank"><img src="assets/logos/voegele_optik.png"></a>
        <a href="https://www.heimberg-immo.ch/" target ="_blank"><img src="assets/logos/heimberg_imobilien.png"></a>
    </div>
    <div class="center mobile">
        <a href="https://skilllab.ch" target ="_blank"><img src="assets/logos/skilllab_logo.png"></a>
        <a href="https://www.mauronmusy.com/fr" target ="_blank"><img src="assets/logos/mauron_musy.png"></a>
        <a class="extra-padding" href="https://www.taegi.ch" target="_blank"><img src="assets/logos/taegi.png"/></a>
    </div>
